import type { ProductPrices } from 'product/types/Prices'

interface CrossedOutPricesProps {
	prices: ProductPrices
	hidePriceHistory: boolean
	hasPriceHistory: boolean
}

export function getCrossedOutPrices({
	prices,
	hidePriceHistory,
	hasPriceHistory,
}: CrossedOutPricesProps) {
	const { price, previousPrices, lowestPrices } = prices

	const minBetweenSaleAndPromo =
		lowestPrices?.sale && lowestPrices?.promo
			? Math.min(lowestPrices.sale, lowestPrices.promo)
			: lowestPrices?.sale || lowestPrices?.promo

	const saleOrPromo =
		!hidePriceHistory && hasPriceHistory && minBetweenSaleAndPromo

	const allCrossedOutPrices = [
		previousPrices?.originalShop,
		previousPrices?.originalOutlet,
		saleOrPromo,
	].filter((p) => p && p !== price) as number[]

	const allCrossedOutWithoutLowest = saleOrPromo
		? allCrossedOutPrices.slice(0, -1)
		: allCrossedOutPrices

	/**
	 * Remove duplicated prices
	 */
	const crossedOutPricesSet = new Set(allCrossedOutPrices)
	const allCrossedOutWithoutLowestSet = new Set(allCrossedOutWithoutLowest)

	/**
	 * Convert crossed out prices to array
	 */
	return {
		crossedOutPrices: Array.from(crossedOutPricesSet),
		allCrossedOutWithoutLowest: Array.from(allCrossedOutWithoutLowestSet),
		hasPromoOrSale: Boolean(saleOrPromo),
	}
}
