'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'

import styles from '../ExtraInfo.module.scss'

export type TitlePops = {
	className?: string
	title: string
}
export const Title = ({ title, className = styles.title }: TitlePops) => {
	const { t } = useLabels()
	return <h3 className={className}>{t(title)}</h3>
}
