import { getProductPrices } from 'product/services/productPrices/getProductPrices'
import useSWRImmutable from 'swr/immutable'
import type { MasterData } from 'types/masterData'

export function useProductPrices(
	masterData: MasterData,
	productId: string,
	shouldRevalidate = false
) {
	const { data } = useSWRImmutable(
		shouldRevalidate ? productId : null,
		() => getProductPrices(masterData, productId),
		{
			revalidateOnMount: true,
		}
	)

	return data
}
