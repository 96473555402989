export interface Microdata {
	itemProp: string
	itemScope: boolean
	itemType: string
}

export function getMicrodata(hasMicrodata: boolean): Microdata | undefined {
	/**
	 * Reports to the search engines that the data shown is a price
	 */
	return hasMicrodata
		? {
				itemProp: 'offers',
				itemScope: true,
				itemType: 'https://schema.org/Offer',
			}
		: undefined
}
