import { conversion } from 'country-configuration/currency/conversion'
import { applyStylesIf, cx } from 'utils/cx'

import type { PriceProps } from '../../types'
import {
	getCrossedOutAdditionalPriceClassName,
	getCrossedOutPriceClassName,
	getWrapperClassname,
} from '../../utils/getStyles/getStyles'
import { PRICE_KEYS } from '../../utils/keys'
import { DiscountRate } from '../DiscountRate/DiscountRate'
import { SinglePrice } from '../SinglePrice/SinglePrice'

import styles from './Price.module.scss'
import text from 'fukku/styles/texts.module.scss'

const classesDefault = {
	price: text.bodyL,
	firstCrossedOutPrice: '',
	crossedOutPrices: '',
	discountRate: text.bodyS,
	additionalPrice: '',
	additionalFirstCrossedOutPrice: '',
	additionalCrossedOutPrices: '',
}

/**
 * @description
 * Internal component to render prices
 *
 * @warning Do not use this component directly to render prices
 *
 * @see {@link ClientPrice}
 * @see {@link ServerPrice}
 * @see {@link ClientProductPrice}
 * @see {@link ServerProductPrice}
 */
export function Price({
	horizontal = false,
	reverse = false,
	align = 'start',
	additionalPriceVertical = false,
	hasMicrodata = false,
	showDiscount = false,
	showDiscountInfo = false,
	prices,
	classes: newClasses = classesDefault,
	country,
	getLabel,
	channel,
	dataTestId,
	priceRef,
	limitDiscount,
	hideSaleOrPromoPrice = false,
	discountRateTestVar,
	showDiscountEnabled,
}: PriceProps) {
	const {
		price,
		discountRate,
		crossedOutPrices = [],
		allCrossedOutWithoutLowest = [],
	} = prices
	const classes = {
		...classesDefault,
		...newClasses,
	}

	const filteredCrossedOut = Array.from(
		new Set(crossedOutPrices.filter((p) => p !== price))
	)

	const horizontalAndFF = horizontal && Boolean(showDiscountEnabled)

	const discountText = discountRate ? `-${discountRate}%` : ''

	const crossedOut =
		hideSaleOrPromoPrice && filteredCrossedOut.length > 0
			? allCrossedOutWithoutLowest
			: filteredCrossedOut

	const { hasPriceHistory } = country
	const { additionalPrices } = conversion(price, country)

	const DiscountRateComponent = (
		<DiscountRate
			align={align}
			channel={channel}
			classes={classes}
			crossedOut={filteredCrossedOut}
			discountRate={discountRate}
			discountText={discountText}
			hasPriceHistory={hasPriceHistory}
			limitDiscount={limitDiscount}
			showDiscountInfo={showDiscountInfo}
			horizontal={horizontal}
			showDiscount={showDiscount}
			price={price}
			hideSaleOrPromoPrice={hideSaleOrPromoPrice}
			discountRateTestVar={discountRateTestVar}
		/>
	)

	return price ? (
		<div
			ref={priceRef}
			className={cx(
				classes.wrapper,
				styles.container,
				styles[align],
				applyStylesIf(horizontal, styles.horizontalContainer),
				applyStylesIf(horizontalAndFF, styles.horizontalExtraInfoRight),
				applyStylesIf(additionalPrices !== undefined, styles.additionalPrice)
			)}
			data-testid={dataTestId ?? 'prices.container'}
		>
			<div
				className={getWrapperClassname({ horizontal, align, reverse, classes })}
			>
				{crossedOut.length > 0 &&
					crossedOut.map((crossedOutPrice, index) => {
						const dataTestSuffix = index >= 1 ? `-${index}` : ''
						return (
							<SinglePrice
								priceClassName={getCrossedOutPriceClassName({ classes, index })}
								additionalPriceClassName={getCrossedOutAdditionalPriceClassName(
									{ classes, index }
								)}
								key={crossedOutPrice.toString()}
								price={crossedOutPrice}
								label={getLabel(PRICE_KEYS[index + 1])}
								isCrossedOut
								additionalPriceVertical={additionalPriceVertical}
								align={align}
								dataTestid={`crossedOutPrice${dataTestSuffix}`}
								country={country}
							/>
						)
					})}

				<SinglePrice
					priceClassName={classes.price}
					price={price}
					additionalPriceClassName={classes.additionalPrice || classes.price}
					label={getLabel(PRICE_KEYS[0])}
					finalPrice={crossedOut.length > 0}
					align={align}
					additionalPriceVertical={additionalPriceVertical}
					dataTestid='currentPrice'
					hasMicrodata={hasMicrodata}
					country={country}
				/>
				{horizontalAndFF && DiscountRateComponent}
			</div>
			{!horizontalAndFF && DiscountRateComponent}
		</div>
	) : null
}
