interface ScreenReaderTextProps {
	label: string
	additionalPrices: string[] | undefined
	priceWithCurrency: string
}

export function getScreenReaderText({
	label,
	additionalPrices,
	priceWithCurrency,
}: ScreenReaderTextProps): string {
	const textReaderAdditionalPrice = additionalPrices
		? ` ${additionalPrices}`
		: ''
	return label?.replace(
		'%1$s',
		`${priceWithCurrency}${textReaderAdditionalPrice}`
	)
}
