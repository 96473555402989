import { type RefObject, useEffect } from 'react'

export function useOutsideClick(
	ref: RefObject<EventTarget> | null,
	callback: (event: MouseEvent) => void
): void {
	function handleClickOutside(event: MouseEvent) {
		if (
			ref?.current &&
			event.target &&
			!(ref.current as HTMLElement).contains(event.target as Node)
		) {
			callback(event)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
}
