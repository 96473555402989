import {
	COLOR_SEARCH_PARAM,
	DEFAULT_LOOK_ID,
	LOOK_SEARCH_PARAM,
} from 'product/constants/constants'
import type { Product, ProductColor } from 'product/types'
import { env } from 'utils/envManager'

interface GetProductUrlProps {
	product: Product
	colorId: ProductColor['id']
	enabled: boolean
	countryISO: string
	languageISO: string
	lookId?: string
}

interface GetUrlByFeatureFlagProps {
	productId: string
	enabled: boolean
	countryISO: string
	languageISO: string
	color?: ProductColor
	lookId?: string
	url?: string
}

function getUrlByFeatureFlag({
	url,
	productId,
	color,
	lookId,
	enabled,
	countryISO,
	languageISO,
}: GetUrlByFeatureFlagProps): string {
	const productUrl = new URL(
		url ??
			`/${countryISO.toLowerCase()}/${languageISO.toLowerCase()}/p/${productId}`,
		env.NEXT_PUBLIC_SITE_BASE_URL
	)

	// Color is optional since we are doing a find by color id
	if (color?.id) {
		productUrl.searchParams.set(COLOR_SEARCH_PARAM, color.id)
	}

	if (lookId && lookId !== DEFAULT_LOOK_ID) {
		productUrl.searchParams.set(LOOK_SEARCH_PARAM, lookId)
	}

	return !enabled ? productUrl.href : productUrl.pathname + productUrl.search
}

export function getProductUrl({
	product,
	colorId,
	lookId,
	enabled,
	countryISO,
	languageISO,
}: GetProductUrlProps): string {
	const color = product.colors.find((c) => c.id === colorId)
	const productId = product.id

	if (!color) {
		return getUrlByFeatureFlag({
			productId,
			color,
			lookId,
			enabled,
			countryISO,
			languageISO,
			url: product.url,
		})
	}

	const relatedProductId = color.relatedProductId
	const productUrl = relatedProductId ? color.relatedProductUrl : product.url

	return getUrlByFeatureFlag({
		color,
		lookId,
		enabled,
		countryISO,
		languageISO,
		productId: relatedProductId ?? productId,
		url: productUrl,
	})
}
