import { BackInStockModalContext } from 'back-in-stock/src/context/BackInStockModalContext/BackInStockModalContext'
import { useContext } from 'react'

export const useBackInStockModalContext = () => {
	const backInStockModalContext = useContext(BackInStockModalContext)

	if (!backInStockModalContext) {
		throw new Error(
			'useBackInStockModalContext must be used within a BackInStockModalProvider'
		)
	}

	return backInStockModalContext
}
