'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { sendPriceAnalytics } from '../../analytics/sendPriceAnalytics'
import { SheetPricesHistory } from './SheetPricesHistory/SheetPricesHistory'
import { TooltipPricesHistory } from './TooltipPricesHistory/TooltipPricesHistory'

export type ExtraInfoProps = {
	hideSaleOrPromoPrice: boolean
	numberOfPrices: number
	pricesHistory: number[]
	shouldShowDiscount: boolean
	horizontal: boolean
	isHightlighted: boolean
}

export type PricesHistoryComponentProps = Omit<
	ExtraInfoProps,
	'isHightlighted'
> & { sendAnalytics: () => void }

export function ExtraInfo({
	hideSaleOrPromoPrice,
	numberOfPrices,
	pricesHistory,
	shouldShowDiscount,
	horizontal,
	isHightlighted,
}: ExtraInfoProps) {
	const { isSmall } = useResponsive()
	const { isApp, channel } = useMasterData()
	const analyticsSent = useRef(false)

	if (isApp) {
		return null
	}

	const sendAnalytics = () => {
		if (!analyticsSent.current) {
			analyticsSent.current = true
			sendPriceAnalytics(
				pricesHistory,
				hideSaleOrPromoPrice,
				isHightlighted,
				channel
			)
		}
	}

	const pricesHistoryProps: PricesHistoryComponentProps = {
		hideSaleOrPromoPrice,
		numberOfPrices,
		pricesHistory,
		shouldShowDiscount,
		horizontal,
		sendAnalytics,
	}

	return isSmall ? (
		<SheetPricesHistory {...pricesHistoryProps} />
	) : (
		<TooltipPricesHistory {...pricesHistoryProps} />
	)
}
