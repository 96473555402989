'use client'

import { Tooltip, type TooltipHandle } from 'fukku/Tooltip/Tooltip'
import { IconInfoL } from 'icons/components/IconInfoL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useRef, useState } from 'react'

import { BodyContent } from '../Content/BodyContent'
import { PRICES_TOOLTIP_TITLE } from '../Content/keys'
import type { PricesHistoryComponentProps } from '../ExtraInfo'
import { ExtraInfoButton } from '../ExtraInfoButton'

import styles from './TooltipPricesHistory.module.scss'

const MARGIN_TOOLTIP = 8

export function TooltipPricesHistory({
	hideSaleOrPromoPrice,
	numberOfPrices,
	pricesHistory,
	shouldShowDiscount,
	horizontal,
	sendAnalytics,
}: PricesHistoryComponentProps) {
	const tooltipRef = useRef<TooltipHandle | null>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const [tooltipShow, setTooltipShow] = useState<boolean>(false)

	useEffect(() => {
		if (tooltipShow) {
			tooltipRef.current?.open()
		} else {
			tooltipRef.current?.close()
		}
	}, [tooltipShow])

	const { channel, country } = useMasterData()
	const { t } = useLabels()

	const reverse = country.isRtl ? !horizontal : horizontal

	const tooltipTitle = hideSaleOrPromoPrice ? PRICES_TOOLTIP_TITLE : null

	const boundary = {
		current: {
			getBoundingClientRect: () => ({
				left: 0,
				right:
					(containerRef.current?.getBoundingClientRect()?.right || 0) +
					MARGIN_TOOLTIP,
			}),
		} as HTMLDivElement,
	}

	const handleOnClick = () => {
		sendAnalytics()
		setTooltipShow(!tooltipShow)
	}

	return (
		<>
			<ExtraInfoButton handleOnClick={handleOnClick} horizontal={horizontal} />
			<div ref={containerRef}>
				<Tooltip
					data-testid='tooltip.history.prices'
					ref={tooltipRef}
					className={styles.tooltip}
					boundary={boundary}
					preventHover
					reverse={reverse}
					content={
						<BodyContent
							numberOfPrices={numberOfPrices}
							channel={channel}
							getLabel={t}
							pricesHistory={pricesHistory}
							shouldShowDiscount={shouldShowDiscount}
							hideSaleOrPromoPrice={hideSaleOrPromoPrice}
							title={tooltipTitle}
						/>
					}
				>
					<div className={styles.icon}>
						<IconInfoL height={12} width={12} />
					</div>
				</Tooltip>
			</div>
		</>
	)
}
