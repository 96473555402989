import { Channel } from 'types/masterData'

export function getOmnibusAnalytics(
	pricesHistory: number[],
	channel: Channel
): {
	priceOmnibus: boolean
	priceOmnibusVsCurrentPrice: 'lower' | 'higher' | null
} {
	const FOUR_PRICES = 4
	const THREE_PRICES = 3
	const INDEX_TWO = 2
	const INDEX_THREE = 3

	if (pricesHistory.length === FOUR_PRICES) {
		// This is when channel is Outlet, is the only channel with 4 prices
		return {
			priceOmnibus: true,
			priceOmnibusVsCurrentPrice:
				pricesHistory[INDEX_TWO] > pricesHistory[INDEX_THREE]
					? 'higher'
					: 'lower',
		}
	} else {
		if (channel === Channel.Shop && pricesHistory.length === THREE_PRICES) {
			return {
				priceOmnibus: true,
				priceOmnibusVsCurrentPrice:
					pricesHistory[1] > pricesHistory[INDEX_TWO] ? 'higher' : 'lower',
			}
		}
	}
	return {
		priceOmnibus: false,
		priceOmnibusVsCurrentPrice: null,
	}
}
