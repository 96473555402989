import type { RemoveFavorite } from 'favorite/types/types'

import { ENDPOINT } from './constants'

export const removeFavorite = ({
	id,
	token,
}: RemoveFavorite): Promise<Response> => {
	return fetch(`${process.env.NEXT_PUBLIC_SITE_BASE_URL}${ENDPOINT}/${id}`, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		next: { revalidate: 0 },
	})
}
