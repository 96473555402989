import { Channel } from 'types/masterData'

const NUM_PRICES_CROSSED_OUT_TO_SHOW_EXTRA_INFO = {
	[Channel.Shop]: 2,
	[Channel.Outlet]: 3,
	[Channel.Backoffice]: 3,
}

export type GetShouldShowExtraInfoProps = {
	showExtraInfo: boolean
	shouldShowDiscount: boolean
	hasPriceHistory: boolean
	crossedOut: number[]
	channel: Channel
}

export const getShouldShowExtraInfo = ({
	showExtraInfo,
	shouldShowDiscount,
	hasPriceHistory,
	crossedOut,
	channel,
}: GetShouldShowExtraInfoProps): boolean => {
	const isException = channel === Channel.Shop && crossedOut.length === 1

	const showDiscountCondition = shouldShowDiscount
		? Boolean(crossedOut.length)
		: crossedOut.length >= NUM_PRICES_CROSSED_OUT_TO_SHOW_EXTRA_INFO[channel]

	return (
		showExtraInfo && hasPriceHistory && showDiscountCondition && !isException
	)
}
