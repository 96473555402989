'use client'

import { conversion } from 'country-configuration/currency'
import type { CountryConfiguration } from 'types/countryConfiguration'
import { cx } from 'utils/cx'

import { getMicrodata } from '../../utils/getMicrodata/getMicrodata'
import { getScreenReaderText } from '../../utils/getScreenReaderText/getScreenReaderText'
import { getAlignStyle } from '../../utils/getStyles/getStyles'

import styles from './SinglePrice.module.scss'
import fukkuClasses from 'fukku/styles/classes.module.scss'

type SinglePriceProps = {
	price: number
	finalPrice?: boolean
	isCrossedOut?: boolean
	additionalPriceVertical?: boolean
	hasMicrodata?: boolean
	align?: 'start' | 'center' | 'end'
	dataTestid?: string
	label: string
	priceClassName?: string
	additionalPriceClassName?: string
	country: CountryConfiguration
}

export function SinglePrice({
	price,
	finalPrice = false,
	isCrossedOut = false,
	additionalPriceVertical = false,
	hasMicrodata = false,
	align = 'start',
	dataTestid,
	label,
	priceClassName,
	additionalPriceClassName,
	country,
}: SinglePriceProps) {
	const { price: priceWithCurrency, additionalPrices } = conversion(
		price,
		country
	)

	const screenReaderText = getScreenReaderText({
		label,
		additionalPrices,
		priceWithCurrency,
	})

	const microdata = getMicrodata(hasMicrodata)

	const alignStyle = getAlignStyle({ align, additionalPriceVertical })

	return (
		<>
			<span className={fukkuClasses.srOnly}>{screenReaderText}</span>
			<span
				className={styles.nowrap}
				aria-hidden='true'
				data-testid={dataTestid}
				{...microdata}
			>
				{hasMicrodata && (
					<>
						<meta itemProp='priceCurrency' content={country.currency.iso} />
						<meta itemProp='price' content={price.toString()} />
					</>
				)}
				<span
					className={cx(
						styles[align],
						additionalPriceVertical ? styles.allVertical : styles.container
					)}
				>
					<span
						className={cx(
							isCrossedOut && styles.crossed,
							alignStyle,
							priceClassName,
							finalPrice && styles.finalPrice
						)}
					>
						{priceWithCurrency}
					</span>
					{additionalPrices && (
						<span
							className={cx(
								isCrossedOut && styles.crossed,
								alignStyle,
								additionalPriceClassName,
								finalPrice && styles.finalPrice,
								additionalPriceVertical && styles.allVerticalSpacing
							)}
						>
							{additionalPrices}
						</span>
					)}
				</span>
			</span>
		</>
	)
}
