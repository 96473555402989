import type { FavoriteProduct } from 'favorite/types/types'

const filterByFavoriteId = (item: FavoriteProduct, product: FavoriteProduct) =>
	item.id !== product?.id

const filterByFavoriteRefColor = (
	{ ref, color }: FavoriteProduct,
	product: FavoriteProduct
) => !(ref === product.ref && color === product.color)

export const chooseFavoritesFilter = (logged: boolean) =>
	logged ? filterByFavoriteId : filterByFavoriteRefColor
