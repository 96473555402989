import { cx } from 'utils/cx'

import { BUTTON_VARIANT } from '../Button.types'
import { ButtonBase } from '../ButtonBase'

import styles from './ButtonPrimary.module.scss'

interface PrimaryProps extends React.ComponentProps<'button'> {
	fullWidth?: boolean
	isLoading?: boolean
	variant?: BUTTON_VARIANT
}

export function ButtonPrimary(props: PrimaryProps): React.ReactNode {
	const {
		isLoading = false,
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		disabled,
		children,
		fullWidth,
		...rest
	} = props

	const disabledButton = disabled || isLoading

	return (
		<ButtonBase
			{...rest}
			className={cx(
				className,
				styles[variant],
				isLoading && styles.loading,
				fullWidth && styles.fullWidth
			)}
			disabled={disabledButton}
		>
			<span className={styles.content}>{children}</span>
		</ButtonBase>
	)
}
