import type { DiscountTestVariable } from '../getDiscountTestVariable/getDiscountTestVariable'

interface ShouldHighlightDiscount {
	discountRateTestVar: DiscountTestVariable | undefined
	isHightlighted: boolean
}
export function shouldHighlightDiscount({
	discountRateTestVar,
	isHightlighted,
}: ShouldHighlightDiscount) {
	if (discountRateTestVar === undefined && isHightlighted) {
		return true
	}
	if (discountRateTestVar === 'show_discount_var0' && isHightlighted) {
		return true
	}
	if (
		discountRateTestVar === 'show_discount_var1' ||
		discountRateTestVar === 'show_discount_var2'
	) {
		return true
	} else {
		return false
	}
}
