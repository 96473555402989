import { type MutableRefObject } from 'react'
import { Keyboard } from 'types/keyboard'

import type { Position } from '../Tooltip'
import {
	type TooltipStyleResult,
	getBottomStyles,
	getLeftStyles,
	getRightStyles,
	getTopStyles,
	getTriggerPositions,
} from './tooltipStyles'

interface OnTriggerChangeParams {
	boundary?: {
		current?: HTMLElement | null
	}
	setLimits: React.Dispatch<
		React.SetStateAction<{ left: number; right: number }>
	>
	minDistance: number
}

interface UpdateTooltipParams {
	show: boolean
	tooltipRef: MutableRefObject<HTMLDivElement | null>
	triggerRef: MutableRefObject<HTMLDivElement | null>
	position: Position
	offset: number
	limits: { left: number; right: number }
	positions: {
		bottom: typeof getBottomStyles
		top: typeof getTopStyles
		left: typeof getLeftStyles
		right: typeof getRightStyles
	}
	reverse: boolean
	setArrowStyles: React.Dispatch<React.SetStateAction<{}>>
	setTooltipStyles: React.Dispatch<React.SetStateAction<{}>>
}

export function onTriggerChange({
	boundary,
	setLimits,
	minDistance,
}: OnTriggerChangeParams) {
	let left = minDistance
	let right = window.innerWidth - minDistance
	if (boundary && boundary.current) {
		const clientRect = boundary.current.getBoundingClientRect()
		left = clientRect.left
		right = clientRect.right
	}
	setLimits({ left, right })
}

export function updateTooltipPosition({
	show,
	tooltipRef,
	triggerRef,
	position,
	offset,
	limits,
	positions,
	reverse,
	setArrowStyles,
	setTooltipStyles,
}: UpdateTooltipParams) {
	if (show && tooltipRef.current && triggerRef.current) {
		const tooltip = tooltipRef.current.getBoundingClientRect()
		const triggerRect = triggerRef.current.getBoundingClientRect()
		const { top, left, bottom, right } = getTriggerPositions(triggerRect)

		const trigger = {
			top,
			left,
			bottom,
			right,
			width: triggerRect.width,
			height: triggerRect.height,
		}

		const style: TooltipStyleResult = positions[position]({
			tooltip,
			trigger,
			offset,
			limits,
			reverse,
		})

		setArrowStyles(style.arrowStyles)
		setTooltipStyles(style.tooltipStyles)
	}
}

export function handleKeyDown({
	handleClose,
	handleOpen,
	e,
	show,
}: {
	handleClose: () => void
	handleOpen: () => void
	e: React.KeyboardEvent
	show: boolean
}) {
	if (e.key === Keyboard.Enter || e.key === Keyboard.Space) {
		e.preventDefault()
		show ? handleClose() : handleOpen()
	} else if (e.key === Keyboard.Escape) {
		handleClose()
	} else {
		// Do nothing
	}
}
