import type { FavoriteProduct } from 'favorite/types/types'

import { useFavorites } from '../context/FavoritesProvider'

export function useCheckFavoriteProduct(product: FavoriteProduct): boolean {
	const favoriteContext = useFavorites()
	const favorites = favoriteContext?.favorites

	if (!favorites) {
		return false
	}

	return favorites.some(
		({ ref, color }) => ref === product.ref && color === product.color
	)
}
