import { type BrandId, OutletBrand, ShopBrand } from 'types/brands'

export const brandsMapper: Record<string, BrandId | string> = {
	[OutletBrand.outlet]: ShopBrand.she,
	[OutletBrand.outletH]: ShopBrand.he,
	[OutletBrand.outletA]: 'girl',
	[OutletBrand.outletO]: 'boy',
	[ShopBrand.she]: ShopBrand.she,
	[ShopBrand.he]: ShopBrand.he,
	[ShopBrand.teen]: ShopBrand.teen,
	[ShopBrand.kids]: ShopBrand.kids,
	[ShopBrand.home]: ShopBrand.home,
}
