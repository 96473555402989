import { cx } from 'utils/cx'

import type { PriceClasses } from '../../types'

import stylesSinglePrice from '../../components/SinglePrice/SinglePrice.module.scss'
import stylesPrice from '../../components/price/Price.module.scss'

export type AlignTypes = 'start' | 'center' | 'end'

export interface AlignStyleProps {
	align?: AlignTypes
	additionalPriceVertical: boolean
}

export function getAlignStyle({
	align,
	additionalPriceVertical,
}: AlignStyleProps) {
	/**
	 * Prices should be centered except when additionalPriceVertical is true
	 */
	const alignment = align && (!additionalPriceVertical || align === 'center')
	return alignment ? stylesSinglePrice[align] : ''
}

interface GetWrapperClassNameProps {
	horizontal: boolean
	align: AlignTypes
	reverse: boolean
	classes: PriceClasses
}

export function getWrapperClassname({
	horizontal,
	align,
	reverse,
	classes,
}: GetWrapperClassNameProps) {
	return cx(
		stylesPrice.wrapper,
		horizontal ? stylesPrice.horizontalWrapper : stylesPrice.vertical,
		align === 'center' && horizontal && stylesPrice.centerWrapper,
		stylesPrice[align],
		reverse && stylesPrice.reverse,
		classes.pricesContainer
	)
}

interface GetCrossedOutClassNameProps {
	classes: PriceClasses
	index: number
}

export function getCrossedOutPriceClassName({
	classes,
	index,
}: GetCrossedOutClassNameProps) {
	return cx(
		classes.crossedOutPrices || classes.price,
		index === 0 ? classes.firstCrossedOutPrice : stylesPrice.restCrossedOutPrice
	)
}

export function getCrossedOutAdditionalPriceClassName({
	classes,
	index,
}: GetCrossedOutClassNameProps) {
	return cx(
		classes.additionalCrossedOutPrices ||
			classes.additionalPrice ||
			classes.crossedOutPrices ||
			classes.price,
		index === 0
			? classes.additionalFirstCrossedOutPrice
			: stylesPrice.restCrossedOutPrice
	)
}
