import { FAVORITES_LIMIT } from 'favorite/constants/constants'
import { getCacheFavorites } from 'favorite/utils/getCacheFavorites'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { SnackbarStatus } from 'notifications/constants/constants'
import { useNotificationsContext } from 'notifications/hooks/useNotificationsContext'
import { PageType } from 'types/analytics'

export function useNotifications(notification: boolean): {
	hasReachedLimit: () => Promise<boolean>
	notifyLimit: () => void
	notifyAndRemoveFavorite: (addFavoriteBack: () => Promise<void>) => void
} {
	const { t } = useLabels()
	const { addNotification } = useNotificationsContext()
	const { pageType } = useMasterData()

	const isInFavoritesPage = pageType === PageType.Favorites

	const hasReachedLimit = async () => {
		const favoritesFromCache = await getCacheFavorites()
		const favoritesSize = favoritesFromCache?.length

		return favoritesSize !== undefined && favoritesSize >= FAVORITES_LIMIT
	}

	const notifyLimit = () => {
		if (!notification) {
			return
		}

		addNotification({
			id: 'product.snackbar.limit.text',
			status: SnackbarStatus.INFO,
			title: replacePlaceholders(
				t('wishlist.snackbar.limit.errormessage.title'),
				{ 1: FAVORITES_LIMIT }
			),
			subtitle: t('wishlist.snackbar.limit.errormessage.subtitle'),
		})
	}

	/**
	 * Gives the user the opportunity to add back the favorite product they just removed.
	 * Only in the favorites page, since there it immediately disappears.
	 */
	const notifyAndRemoveFavorite = async (
		addFavoriteBack: () => Promise<void>
	) => {
		if (!notification || !isInFavoritesPage) {
			return
		}

		addNotification({
			id: 'product.snackbar.addBack.text',
			title: t('wishlist.snackbar.removed.text'),
			status: SnackbarStatus.INFO,
			buttonText: t('wishlist.snackbar.undo.button'),
			onClick: addFavoriteBack,
		})
	}

	return { hasReachedLimit, notifyLimit, notifyAndRemoveFavorite }
}
