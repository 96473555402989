'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { isBot } from 'utils/isBot/IsBot'

import { useProductPrices } from '../hook/useProductPrices/useProductPrices'
import type { ClientProductPriceProps } from '../types'
import { getCrossedOutPrices } from '../utils/getCrossedOutPrices/getCrossedOutPrices'
import { ClientPrice } from './ClientPrice'

/**
 * @description
 * Renders the price and discounts of a product on the client side.
 *
 * @example
 * <ClientProductPrice product="123" color="01" />
 *
 * @example
 * <ClientProductPrice
 * product="123"
 * color="01"
 * horizontal
 * reverse
 * align="start"
 * additionalPriceVertical
 * hasMicrodata
 * hidePriceHistory
 * showDiscount
 * showDiscountInfo
 * classes={{
 * 		price: text.bodyL,
 * 		crossedOutPrices: '',
 * 		firstCrossedOutPrice: '',
 * 		discountRate: text.bodyS,
 * 		additionalPrice: '',
 * 		additionalFirstCrossedOutPrice: '',
 * 		additionalCrossedOutPrices: '',
 * }}
 * />
 *
 * @see {@link ServerProductPrice}
 * @see {@link ClientPrice}
 * @link https://schema.org/docs/gs.html#microdata_itemscope_itemtype_itemprop microdata
 */
export const ClientProductPrice = ({
	product,
	color,
	prices: pricesFromServer,
	shouldRevalidate = true,
	...rest
}: ClientProductPriceProps) => {
	const masterData = useMasterData()
	const {
		country: { hasPriceHistory },
	} = masterData

	const hasPricesFromServer = Boolean(pricesFromServer?.price)
	const shouldFetchIfServer = hasPricesFromServer && !isBot()
	const shouldFetchIfClient = !hasPricesFromServer

	const shouldFetch = shouldRevalidate
		? shouldFetchIfServer || shouldFetchIfClient
		: false

	const data = useProductPrices(masterData, product, shouldFetch)
	const productPrices = shouldRevalidate
		? data?.[color] || pricesFromServer
		: pricesFromServer

	if (!productPrices?.price) {
		return null
	}

	const { crossedOutPrices, allCrossedOutWithoutLowest } = getCrossedOutPrices({
		prices: productPrices,
		hidePriceHistory: Boolean(rest.hidePriceHistory),
		hasPriceHistory,
	})

	return (
		<ClientPrice
			{...rest}
			prices={{
				price: productPrices.price,
				discountRate: productPrices.discountRate,
				crossedOutPrices,
				allCrossedOutWithoutLowest,
			}}
		/>
	)
}
