import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import type { BrandId, ShopBrand } from 'types/brands'
import { Channel } from 'types/masterData'
import { brandsMapper } from 'utils/brandsMapper/brandsMapper'

interface LimitDiscountProps {
	channel: Channel
	brand: BrandId | null
}

interface HighlightedDiscountFeatureFlag {
	enabled: boolean
	variables: {
		limit_discounts: {
			default?: {
				she: number
				he: number
				kids: number
				teen: number
			}
		}
	}
}

type PriceBrand = ShopBrand.she | ShopBrand.he | ShopBrand.kids | ShopBrand.teen

export function useLimitDiscount({ channel, brand }: LimitDiscountProps) {
	const {
		enabled,
		variables: { limit_discounts },
	} = useFeatureFlag<HighlightedDiscountFeatureFlag>(
		featureFlagKeys.HighlightedDiscount
	)

	const brandId = brand ? (brandsMapper[brand] as PriceBrand) : undefined
	if (channel === Channel.Outlet && enabled && brandId) {
		return limit_discounts?.default?.[brandId]
	}

	return undefined
}
