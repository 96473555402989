'use client'

import { PRODUCT_INFO_CLIENT_SERVICE_URL } from 'product/constants/constants'
import type { Product } from 'product/types'
import { fetcher } from 'services/rest/fetcher'
import useSWRImmutable from 'swr/immutable'
import { env } from 'utils/envManager'

import type { GetProductInfoProps } from '../getProductInfo'

export const useProductInfo = ({
	language,
	country,
	productId,
	enabled = true, // This property is for calling the hook on demand, a flow that we need to manage the related products in the product card.
}: GetProductInfoProps) => {
	const basePath = `${PRODUCT_INFO_CLIENT_SERVICE_URL}${productId}`
	const searchParams = new URLSearchParams({
		countryIso: country,
		channelId: env.NEXT_PUBLIC_CHANNEL,
		language,
	})

	const url = new URL(basePath, env.NEXT_PUBLIC_SITE_BASE_URL)

	url.search = searchParams.toString()

	const fetchUrl = url.href

	const { data, isLoading, mutate, error } = useSWRImmutable<Product>(
		enabled ? fetchUrl : null,
		fetcher,
		{ revalidateOnMount: true }
	)
	const product: Product | undefined = data

	return {
		data: product ? { ...product, id: productId } : undefined,
		isLoading,
		mutate,
		error,
	}
}
