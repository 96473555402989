'use client'

import { useEECEvents } from 'analytics/hooks/useEECEvents/useEECEvents'
import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import {
	DEFAULT_LOOK_ID,
	SELECTED_SIZE_ID_KEY,
} from 'product/constants/constants'
import { env } from 'utils/envManager'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'
import { removeFromSessionStorage } from 'utils/webStorage/sessionStorage'

import type { PdpLinkProps } from '../types'
import { getProductUrl } from '../utils/getProductUrl/getProductUrl'

export function PdpClientLink({
	product,
	colorId,
	lookId,
	sizeId,
	children,
	linkProps,
	location,
	skipTabKey,
	listId,
	metaAs,
}: PdpLinkProps): React.ReactNode {
	const { enabled } = useFeatureFlag(featureFlagKeys.GenesisPdp)
	const { sendSelectItem } = useEECEvents()
	const {
		country: { countryISO, languageISO },
	} = useMasterData()

	const productUrl = getProductUrl({
		product,
		colorId,
		lookId,
		enabled,
		countryISO,
		languageISO,
	})

	const href = productUrl.startsWith('/')
		? `${env.NEXT_PUBLIC_CURRENT_ORIGIN}${productUrl}`
		: productUrl

	const props: ClientLinkProps = {
		...linkProps,
		children,
		href,
		isLocalizedPath: true,
		withLegacyLocale: !enabled ? true : undefined,
		prefetch: false,
		onClick: () => {
			sendSelectItem({
				item: {
					colorId,
					sizeId,
					lookId: lookId || DEFAULT_LOOK_ID,
					productId: product.id,
					quantity: 1,
				},
				location,
				listId,
			})
			removeFromSessionStorage(SELECTED_SIZE_ID_KEY)
		},
		skipTabKey,
	}

	return (
		<>
			{metaAs && <meta itemProp={metaAs} content={href} />}
			<ClientLink {...props}>{children}</ClientLink>
		</>
	)
}
