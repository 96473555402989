'use client'

import { Channel } from 'types/masterData'
import { applyStylesIf, cx } from 'utils/cx'

import { PricesHistory } from './PricesHistory'
import { Title } from './Title'

import styles from '../ExtraInfo.module.scss'
import text from 'fukku/styles/texts.module.scss'

const MAX_PRICES_TO_SHOW_MESSAGE = 3

export type BodyContentProps = {
	numberOfPrices: number
	channel: Channel
	pricesHistory: number[]
	getLabel: (key: string, keys?: Record<string, string> | undefined) => string
	shouldShowDiscount: boolean
	hideSaleOrPromoPrice: boolean
	title?: string | null
}

export function BodyContent({
	numberOfPrices,
	channel,
	pricesHistory,
	getLabel,
	shouldShowDiscount,
	hideSaleOrPromoPrice,
	title,
}: BodyContentProps) {
	const priceDescriptionLabel =
		channel === Channel.Shop
			? 'productCard.discountDescription.text'
			: 'productCard.discountDescription.outlet.text'

	return (
		<div className={styles.bodyContent}>
			{hideSaleOrPromoPrice && (
				<>
					{title && <Title title={title} />}
					<PricesHistory
						pricesHistory={pricesHistory}
						getLabel={getLabel}
						channel={channel}
					/>
				</>
			)}

			{shouldShowDiscount && (
				<p
					className={cx(
						text.bodyS,
						styles.pricesDescription,
						applyStylesIf(hideSaleOrPromoPrice, styles.pricesMargin)
					)}
				>
					{getLabel(priceDescriptionLabel)}
				</p>
			)}

			{channel === Channel.Outlet &&
				!hideSaleOrPromoPrice &&
				numberOfPrices > MAX_PRICES_TO_SHOW_MESSAGE && (
					<p className={cx(text.bodyS, styles.pricesDescription)}>
						{getLabel('pdp.previousPrice.outlet.tooltip')}
					</p>
				)}
		</div>
	)
}
